var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menuTemplate"},[_c('section',[(_vm.featuredScreen)?_c('div',[_c('featuredParent',{attrs:{"featuredScreen":_vm.featuredScreen}})],1):_vm._e(),_c('div',{staticClass:"home-slider-up-parent"},[(_vm.categoryScreens.length > 0)?_c('div',{staticClass:"home-slider-view"},_vm._l((_vm.categoryScreens),function(item,index){return _c('div',{key:index},[(
              (item.sectionType === 'CONTINUEWATCH' || item.sectionType === 'CONTINUEWATCHING') && (item.status == 'ACTIVE' || item.isSectionEnabled)
            )?_c('continueSlider',{attrs:{"screen":item,"playerInstance":_vm.playerInstance}}):_vm._e(),(
              (item.sectionType !== 'CONTINUEWATCH' && item.sectionType !== 'CONTINUEWATCHING')  &&
                (item.isSectionEnabled || item.status == 'ACTIVE') &&
                (!item.userTypes ||
                  (item.userTypes && item.userTypes.includes('ALL')) ||
                  (item.userTypes && item.userTypes.includes(_vm.getUserType())))
            )?_c('Slider',{attrs:{"screen":item,"id":_vm.getIdValue(item.title.eng) + '-' +index,"playerInstance":_vm.playerInstance,"counterTextV":index,"screenName":_vm.getIdValue(item.title.eng) + index}}):_vm._e()],1)}),0):_vm._e(),_c('div',{})])])])}
var staticRenderFns = []

export { render, staticRenderFns }